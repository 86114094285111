import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, getContext,
} from '@/auth/utils'
import spot from './routes-spot'
import futures from './routes-futures'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/spot', redirect: { name: 'home' } },
    { path: '/futures', redirect: { name: 'fut-home' } },
    ...spot,
    ...futures,
    {
      path: '/notificacao',
      name: 'notificacao',
      component: () => import('@/views/notificacao/Notificacao.vue'),
      meta: {
        resource: ['pendente', 'assinante', 'assinante-futures'],
      },
    },
    {
      path: '/conta/configuracao',
      name: 'conta-configuracao',
      component: () => import('@/views/conta/configuracao/ContaConfiguracao.vue'),
      meta: {
        resource: ['pendente', 'assinante', 'assinante-futures'],
        pageTitle: 'Ajustes',
        breadcrumb: [
          {
            text: 'Conta',
            active: true,
          },
        ],
      },
    },
    {
      path: '/indicacao',
      name: 'indicacao',
      component: () => import('@/views/indicacao/Indicacao.vue'),
      meta: {
        resource: 'multinivel',
      },
    },
    {
      path: '/indicacao/extrato',
      name: 'indicacao-extrato',
      component: () => import('@/views/indicacao/extrato/Extrato.vue'),
      meta: {
        resource: 'multinivel',
        pageTitle: 'Extrato',
        breadcrumb: [
          {
            text: 'Indicação',
            active: true,
          },
        ],
      },
    },
    {
      path: '/indicacao/rede',
      name: 'indicacao-rede',
      component: () => import('@/views/indicacao/rede/Rede.vue'),
      meta: {
        resource: 'multinivel',
        pageTitle: 'Rede',
        breadcrumb: [
          {
            text: 'Indicação',
            active: true,
          },
        ],
      },
    },
    {
      path: '/indicacao/saque',
      name: 'indicacao-saque',
      component: () => import('@/views/indicacao/saque/Saque.vue'),
      meta: {
        resource: 'multinivel',
        pageTitle: 'Saque',
        breadcrumb: [
          {
            text: 'Indicação',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ativacao',
      name: 'ativacao',
      component: () => import('@/views/conta/ativacao/Ativacao.vue'),
      meta: {
        resource: 'pendente',
      },
    },
    {
      path: '/checkout/:nomePlano/:nomePlanoVariacao',
      name: 'checkout',
      component: () => import('@/views/checkout/Checkout.vue'),
      meta: {
        resource: 'pendente',
        pageTitle: 'Checkout',
      },
    },
    {
      path: '/checkout/:nomePlano/:nomePlanoVariacao/upgrade',
      name: 'checkout-upgrade',
      component: () => import('@/views/checkout/CheckoutUpgrade.vue'),
      meta: {
        resource: ['assinante', 'assinante-futures'],
        pageTitle: 'Checkout Upgrade',
      },
    },
    {
      path: '/checkout/:nomePlano/:nomePlanoVariacao/recorrencia',
      name: 'checkout-recorrencia',
      component: () => import('@/views/checkout/CheckoutRecorrencia.vue'),
      meta: {
        resource: ['assinante', 'assinante-futures'],
        pageTitle: 'Checkout',
      },
    },
    {
      path: '/checkout/credito',
      name: 'checkout-credito',
      component: () => import('@/views/checkout/CheckoutCredito.vue'),
      meta: {
        resource: ['assinante', 'assinante-futures'],
        pageTitle: 'Checkout',
      },
    },
    {
      path: '/conta/assinatura',
      name: 'conta-assinatura',
      component: () => import('@/views/conta/assinatura/Assinatura.vue'),
      meta: {
        resource: ['assinante', 'assinante-futures'],
        pageTitle: 'Assinatura',
        breadcrumb: [
          {
            text: 'Conta',
            active: true,
          },
        ],
      },
    },
    {
      path: '/conta/credito/extrato',
      name: 'conta-credito-extrato',
      component: () => import('@/views/conta/credito/Extrato.vue'),
      meta: {
        resource: ['assinante', 'assinante-futures'],
        pageTitle: 'Crédito',
        breadcrumb: [
          {
            text: 'Conta',
            active: true,
          },
        ],
      },
    },
    {
      path: '/controle/assinante',
      name: 'controle-assinante',
      component: () => import('@/views/controle/assinante/Assinante.vue'),
      meta: {
        resource: 'administrador',
        pageTitle: 'Assinantes',
        breadcrumb: [
          {
            text: 'Controle',
            active: true,
          },
        ],
      },
    },
    {
      path: '/controle/assinante/:id',
      name: 'controle-assinante-edit',
      component: () => import('@/views/controle/assinante/AssinanteEdit.vue'),
      meta: {
        resource: 'administrador',
        pageTitle: 'Assinantes',
        breadcrumb: [
          {
            text: 'Controle',
            active: true,
          },
        ],
      },
    },
    {
      path: '/backtester/backtest',
      name: 'backtester-backtest',
      component: () => import('@/views/backtester/backtest/Backtest.vue'),
      meta: {
        resource: ['agente-futures', 'agente-futures-robo'],
        pageTitle: 'Backtest',
        breadcrumb: [
          {
            text: 'Backtest',
            active: true,
          },
        ],
      },
    },
    {
      path: '/backtester/backtest/:id/painel',
      name: 'backtester-backtest-painel',
      component: () => import('@/views/backtester/backtest/BacktestPainel.vue'),
      meta: {
        resource: ['agente-futures', 'agente-futures-robo'],
        pageTitle: 'Backtest',
        breadcrumb: [
          {
            text: 'Backtest',
            active: false,
          },
          {
            text: 'Painel',
            active: true,
          },
        ],
      },
    },
    {
      path: '/backtester/backtest/:id/relatorio',
      name: 'backtester-backtest-relatorio',
      component: () => import('@/views/backtester/backtest/BacktestRelatorio.vue'),
      meta: {
        resource: ['agente-futures', 'agente-futures-robo'],
        pageTitle: 'Backtest',
        breadcrumb: [
          {
            text: 'Backtest',
            active: false,
          },
          {
            text: 'Relatório',
            active: true,
          },
        ],
      },
    },
    {
      path: '/backtester/carteira',
      name: 'backtester-carteira',
      component: () => import('@/views/backtester/carteira/Carteira.vue'),
      meta: {
        resource: ['agente-futures', 'agente-futures-robo'],
        pageTitle: 'Carteira',
        breadcrumb: [
          {
            text: 'Carteira',
            active: true,
          },
        ],
      },
    },
    {
      path: '/backtester/carteira/:id/relatorio',
      name: 'backtester-carteira-relatorio',
      component: () => import('@/views/backtester/carteira/CarteiraRelatorio.vue'),
      meta: {
        resource: ['agente-futures', 'agente-futures-robo'],
        pageTitle: 'Backtest',
        breadcrumb: [
          {
            text: 'Carteira',
            active: false,
          },
          {
            text: 'Relatório',
            active: true,
          },
        ],
      },
    },
    {
      path: '/register',
      name: 'auth-register',
      component: () => import('@/views/autenticacao/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register/:sponsor',
      name: 'auth-register-sponsor',
      component: () => import('@/views/autenticacao/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/autenticacao/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/login/impersonate',
      name: 'auth-login-impersonate',
      component: () => import('@/views/autenticacao/LoginImpersonate.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/tutorial',
      name: 'tutorial',
      component: () => import('@/views/tutorial/TutorialLista.vue'),
      meta: {
        pageTitle: 'Tutoriais',
        resource: ['pendente', 'assinante', 'assinante-futures'],
        breadcrumb: [
          {
            text: 'Tutoriais',
          },
        ],
      },
    },
    {
      path: '/tutorial/:slugCategoria/:slug',
      name: 'tutorial-conteudo',
      component: () => import('@/views/tutorial/TutorialConteudo.vue'),
      meta: {
        pageTitle: 'Conteúdo',
        resource: ['pendente', 'assinante', 'assinante-futures'],
        breadcrumb: [
          {
            text: 'Tutoriais',
            to: '/tutorial',
          },
          {
            text: 'Conteúdo',
            active: true,
          },
        ],
        navActiveLink: 'tutorial',
      },
    },
    {
      path: '/recuperar-senha',
      name: 'recuperar-senha',
      component: () => import('@/views/RecuperarSenha.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },

    {
      path: '/trocar-senha',
      name: 'trocar-senha',
      component: () => import('@/views/TrocarSenha.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },

    {
      path: '/suporte',
      name: 'suporte',
      component: () => import('@/views/suporte/Suporte.vue'),
      meta: {
        resource: ['pendente', 'assinante', 'assinante-futures'],
        pageTitle: 'Suporte',
        breadcrumb: [
          {
            text: 'Suporte',
            active: true,
          },
        ],
      },
    },

    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/autenticacao/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },

    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // trata o link "/" para o lugar certo
  if (to.redirectedFrom === '/') {
    if (getContext() === 'futures') {
      return next({ name: 'fut-home' })
    } if (getContext() === 'spot') {
      return next({ name: 'home' })
    }
    return next({ name: 'ativacao' })
  }

  //  corrige o contexto de acordo com o link
  // if (!to.path.includes(getContext(), 1) && !to.path.includes('/error-404') && !to.path.includes('/not-authorized')) {
  // setContext(to.path.split('/')[1])
  // console.log(to.path)
  // }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized1
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.perfil : null))
  }

  return next()
})

export default router
